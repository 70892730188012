@import url(./fonts.css);

body {
  margin: 0;
  font-family: "Rawline", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 16px;
}

.wrapper {
  width: 1120px;
  margin: 0 auto;
}

@media screen and (max-width: 1120px) {
  .wrapper {
    width: 900px;
  }
}

@media screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }

  .wrapper {
    width: 100%;
    padding: 0 5%;
  }
}

/* Modal Effect */
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

@media screen and (max-width: 900px) {
  .ReactModal__Content {
    max-width: 90vw !important;
    height: calc(90vw / 1.78) !important;
  }
}
