.page-loader{
  z-index: 1000;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;

  .loader-holder {
    margin-top: -5px;

    .loader {
      height: 18px;
      width: 18px;
      color: #FFFFFF;

      &:nth-child(1) {
        margin-right: 8px;
      }

      &:nth-child(3) {
        margin-left: 8px;
      }
    
      &.text-primary {
        color: #034483 !important;
      }
    }
  }

  &.suspense {
    background: #F5F5F5;
    .loader-holder {
      .loader {
        color: #545454;
      }
    }
  }
}